// 引入所需方法，添加api
// putRequest, deleteRequest
import { getRequest, postRequest } from '../utils/request'

// 登陆接口
export async function login(params) {
  return postRequest(`/api/login`, params)
}
// 发送验证码接口
export async function sendCaptchaForLogin(params) {
  return postRequest(`/api/find/sendCaptchaForLogin`, params)
}

// 上传专家注册信息
export async function uploadExpertData(params) {
  return postRequest(`/api/user/signup/expertUser`, params, 'upload')
}

// 上传单位会员信息
export async function uploadUnitData(params) {
  return postRequest(`/api/user/signup/groupUser`, params, 'upload')
}

// 上传个人会员信息
export async function uploadPersonData(params) {
  return postRequest(`/api/user/signup/personalUser`, params)
}

// 获取所有人员信息
export async function getAllUsers(params) {
  return getRequest(`/api/user/getAll`, params)
}

// 审核用户状体
export async function updateStatus(params) {
  return postRequest('/api/auditRegStatus', params)
}

// 获取某一类型人员
export async function getOneTypeList(params) {
  return getRequest('/api/user/getTypeUser', params)
}

// 添加管理员
export async function addAdmin(params) {
  return postRequest('/api/user/signup/adminUser', params)
}

// 更新管理员密码
// export async function updateAdmin(params) {
//   return getRequest('/api/user/updateAdminPwd', params)
// }

// 更新用户密码
export async function updateMemberPwd(params) {
  return postRequest('/api/user/update/pwd', params)
}

// 更新用户密码加强版
export async function updateMemberPwdPro(params) {
  return postRequest('/api/user/update/pwdPro', params)
}

// 编辑人员信息
export async function updateMemberInfo(params) {
  return postRequest('/api/user/update/userInfo', params)
}

// 获取单个会员所有信息
export async function getOneAllData(params) {
  return postRequest('/api/user/getSingleUser', params)
}

// 获取修改的单个用户信息
export async function getModifiedOneAllData(params) {
  return postRequest('/api/user/getModifiedSingleUser', params)
}

// 修改信息审核提交
export async function auditSingleUserInfo(params) {
  return postRequest('/api/user/auditSingleUserInfo', params)
}

// 修改会员信息提交
export async function submitModifiedUserInfo(params) {
  return postRequest('/api/user/submitModifiedUserInfo', params)
}

// 删除管理员
export async function delAdmin(params) {
  return getRequest('/api/user/delete/adminUser', params)
}

// 删除个人会员
export async function delPerson(params) {
  return getRequest('/api/user/delete/personalUser', params)
}

// 删除单位会员
export async function delUnit(params) {
  return getRequest('/api/user/delete/groupUser', params)
}

// 删除专家会员
export async function delExpert(params) {
  return getRequest('/api/user/delete/expertUser', params)
}

// 下载单位会员已上传的表格
export async function downUploadedTable(params) {
  return postRequest(`/api/user/signup/checkedFileDonwload`, params, 'blob')
}

// 下载单位会员空表格
export async function downTable(params) {
  return postRequest(`/api/user/signup/confirmFileDonwload`, params, 'blob')
}

// 下载某一类型表格（专家、组织、个人）
export async function downOneTable(params) {
  return postRequest(`/api/user/exportUserTable`, params, 'blob')
}

// 通过查询条件下载个人会员信息Excel
// export async function downloadPersonList(params) {
//   return getRequest(`/api/user/downloadPersonList`,params,'blob')
// }

// 下载个人证书
export async function downOneCertificate(params) {
  return postRequest(`/api/fileUpload/generateCertAndDownload`, params, 'blob')
}

// 会员vid更新赋值
export async function updateVID(params) {
  return postRequest(`/api/user/updateVID`, params)
}

// 获取个人所有登录记录
export async function getOneAllLoginInfo(params) {
  return getRequest(`/api/user/getAllLog`, params)
}

// 判断会员账号是否存在
export async function judgeAccount(params) {
  return postRequest(`/api/user/signup/judgeAccount`, params)
}

//获取专家信息列表
export async function getExpertList(params) {
  return postRequest('/api/expert/getList', params)
}

// 新增专家
export async function addExpertUser(params) {
  return postRequest('/api/user/adminAdd/expertUser', params)
}

//获取专家需要评审项目的列表
export async function getExamineList(params) {
  return postRequest('/api/expert/getExamineList', params)
}

//获取专家需要评审项目的列表(在本地数据库中)
export async function getExamineListIndb(params) {
  return postRequest('/api/expert/getExamineListIndb', params)
}

//获取专家需要评审项目的列表(在本地数据库中且带评审文件)
export async function getExamineListForExpertInDb(params) {
  return postRequest('/api/expert/getExamineListForExpertInDb', params)
}

//获取分页视频
export async function selectToPage(params) {
  return getRequest('/api/video/selectToPage', params)
}

//为某个视频添加浏览记录
export async function browseOnce(params) {
  return postRequest('/api/video/browseOnce', params)
}

//专家评分动作
export async function markExamine(params) {
  return postRequest('/api/expert/doExamine', params)
}

//
export async function selectProjectScore(params) {
  return getRequest(`/api/expert/selectScore`, params)
}

//获取单位下所有成员列表
export async function getGroupMembers(params) {
  return postRequest('/api/group/getGroupMembers', params)
}


export async function simpleDelFile(params) {
  return getRequest(`/api/fileUpload/simpleDelFile`, params)
}

export async function delReviewFile(params) {
  return getRequest(`/api/expert/delFile`, params)
}

//发送邮箱验证码
export async function sendCaptcha(params) {
  return postRequest('/api/find/sendCaptcha', params)
}

//验证邮箱验证码，并修改用户密码
export async function checkCaptcha(params) {
  return postRequest('/api/find/checkCaptcha', params)
}
//批量发送邮件
export async function batchInsertMailLog(params) {
  return postRequest('/api/mail/batchInsertMailLog', params)
}

//快捷查询收件人
export async function quickQueryMail(params) {
  return postRequest('/api/mail/quickQueryMail', params)
}

//条件查询收件人
export async function selectAddressee(params) {
  return postRequest('/api/mail/selectAddressee', params)
}

//条件查询邮件发送日志
export async function findEmailLogByPage(params) {
  return postRequest('/api/mail/findEmailLogByPage', params)
}

//手动批量发送邮件（状态改成待发送）
export async function sendMail(params) {
  return postRequest('/api/mail/sendMail', params)
}

//删除附件
export async function delFileInDBAndLocal(params) {
  return postRequest('/api/fileUpload/delFileInDBAndLocal', params)
}

//快捷查询短信收件人
export async function quickQuerySmsReceiver(params) {
  return postRequest('/api/sms/quickQuerySmsReceiver', params)
}

//群发短信
export async function batchSendSMS(params) {
  return postRequest('/api/sms/batchSendSMS', params)
}

//批量检查手机号
export async function batchCheckPhone(params) {
  return postRequest('/api/sms/batchCheckPhone', params)
}

//条件查询短信发送日志
export async function findSmsLogByPage(params) {
  return postRequest('/api/sms/findSmsLogByPage', params)
}
//根据机构名获取机构信息
export async function getOrgByName(params) {
  return getRequest(`/api/org/getOrgByName`, params)
}
//注册页面新增机构
export async function regInsertOrg(params) {
  return postRequest('/api/org/regInsertOrg', params)
}

//条件查询单位库
export async function getOrgByPage(params) {
  return postRequest('/api/org/queryPage', params)
}

//单位库中更新单位信息
export async function orgUpdate(params) {
  return postRequest('/api/org/update', params)
}

//单位库中新增单位信息
export async function orgInsert(params) {
  return postRequest('/api/org/insert', params)
}

//单位库中删除单位信息
export async function orgDeleteById(params) {
  return postRequest('/api/org/deleteById', params)
}

// 注册时，判断单位名称是否存在
export async function checkOrgName(params) {
  return postRequest(`/api/org/checkOrgName`, params)
}

//批量启用单位库
export async function batchUpdateStatus(params) {
  return postRequest('/api/org/batchUpdateStatus', params)
}

// 个人会员页面 分页查询
export async function selectUserToPage(params) {
  return postRequest(`/api/user/selectUserToPage`, params)
}

// 单位会员页面 分页查询
export async function selectGroupToPage(params) {
  return postRequest(`/api/group/selectToPage`, params)
}

// 专家会员页面 分页查询
export async function selectExpertToPage(params) {
  return postRequest(`/api/expert/queryPage`, params)
}

// 理事会员页面 分页查询
export async function selectLishiToPage(params) {
  return postRequest(`/api/user/selectUserToPageInLishi`, params)
}

// 单位会员页面 修改会员编码
export async function updateUnitMemberSerial(params) {
  return postRequest(`/api/group/updateMemberSerial`, params)
}

// 个人会员页面 修改会员编码
export async function updatePersonMemberSerial(params) {
  return postRequest(`/api/user/updateMemberSerial`, params)
}


// 下载个人会员证书
export async function generatePersonCertAndDownload(params) {
  return postRequest(`/api/fileUpload/generateCertAndDownload`, params, 'blob')
}

// 下载专家会员证书
export async function generateExpertCertificateAndDownload(params) {
  return postRequest(`/api/fileUpload/generateExpertCertificateAndDownload`, params, 'blob')
}

// 根据学历统计单位会员数量
export async function personEdu(params) {
  return getRequest(`/api/statistics/person/edu`, params)
}

// 根据单位类型统计单位会员数量
export async function unitType(params) {
  return getRequest(`/api/statistics/unit/type`, params)
}

// 根据地域分布统计单位会员数量
export async function unitRegion(params) {
  return getRequest(`/api/statistics/unit/region`, params)
}

// 根据地域分布统计个人会员数量
export async function personRegion(params) {
  return getRequest(`/api/statistics/person/region`, params)
}

// 视频管理分页查询
export async function selectVideoToPage(params) {
  return getRequest(`/api/video/selectToPage`, params)
}

// 视频新增或修改
export async function VideoInsertOrUpdate(params) {
  return postRequest(`/api/video/normalInsertOrUpdate`, params)
}

// 根据id删除单个视频
export async function VideoDelete(params) {
  return postRequest(`/api/video/deleteByID`, params)
}

// 根据id置顶某个视频
export async function VideoTop(params) {
  return postRequest(`/api/video/topping`, params)
}

// 分页查询视频浏览记录
export async function browsingRecords(params) {
  return getRequest(`/api/video/browsingRecords`, params)
}

// 异常登录信息 分页查询
export async function AbnormalQueryPage(params) {
  return postRequest(`/api/loginDefense/queryPage`, params)
}

// 异常登录信息 根据ID删除记录
export async function AbnormalDeleteById(params) {
  return getRequest(`/api/loginDefense/deleteById`, params)
}

//文章管理 分页查询
export async function ArticleSelectToPage(params) {
  return getRequest(`/api/article/selectToPage`, params)
}

//文章管理 新增或修改
export async function ArticleAddOrUpdate(params) {
  return postRequest(`/api/article/normalInsertOrUpdate`, params)
}

//文章管理 根据id删除单个
export async function ArticleDeleteById(params) {
  return postRequest(`/api/article/deleteByID`, params)
}

//文章管理 将某个文章置顶
export async function ArticleTopping(params) {
  return postRequest(`/api/article/topping`, params)
}

//文章管理 为某个文章添加浏览记录
export async function ArticleBrowseOnce(params) {
  return postRequest(`/api/article/browseOnce`, params)
}

//文章管理 分页查询文章浏览记录
export async function ArticleBrowsingRecords(params) {
  return getRequest(`/api/article/browsingRecords`, params)
}

//文章管理 根据id查询文件信息
export async function getAttachInArticle(params) {
  return getRequest(`/api/article/getAttachInArticle`, params)
}

//科技成果评价 
/** 项目分页查询  */
export async function queryKjcgListPage(params) {
  return getRequest('/api/project/queryListPage', params);
}
/** 查看科技成果评价项目详情  */
export async function queryKjcgById(params) {
  return getRequest('/api/project/queryById', params);
}
/** 项目信息新增或修改接口  */
export async function kjcgAddOrUpdate(params) {
  return postRequest('/api/project/addOrUpdate', params);
}
/**根据项目id查询对应专家信息 */
export async function queryExpByProjectId(params) {
  return getRequest('/api/project/queryExpByProjectId', params);
}
/** 给项目分配评分专家  */
export async function kjcgDistribution(params) {
  return postRequest('/api/project/Distribution', params);
}
/** 给项目分配评分专家(待审核状态)  */
export async function kjcgExpExamine(params) {
  return postRequest('/api/project/expExamine', params);
}
/** 专家对应项目信息列表  */
export async function projectExpertList(params) {
  return getRequest('/api/project/projectExpertList', params);
}
/** 审批日志  */
export async function queryKjcgLogById(params) {
  return getRequest('/api/project/queryLogById', params);
}
/** 生成申请表  */
export async function getSqbfjById(params) {
  return postRequest('/api/project/getSqbfjById?id=' + params.id, params);
}
/** 项目审批  */
export async function projectExamine(params) {
  return postRequest('/api/project/projectExamine', params);
}
/** 查询当前登陆人退回修改,公式有异议的项目数量 */
export async function getErrorKjcgNum(params) {
  return getRequest('/api/project/getErrorNum', params);
}
/** 发送信息 */
export async function sendMessage(params) {
  return postRequest('/api/project/sendMessage', params);
}
/** 回复信息 */
export async function inviteReply(params) {
  return getRequest('/api/reply/mail', params);
}
/** 修改参会状态 */
export async function updateExpJoinStatus(params) {
  return postRequest('/api/project/updateExpJoinStatus', params);
}
// /** 根据项目id删除项目  */
export async function deleteKjcgProject(params) {
  return getRequest('/api/project/deleteProject', params);
}

const simpleUploadFile = '/api/fileUpload/simpleUploadFile';
const multipleUploadFile = '/api/fileUpload/multipleUploadFile';
//根据文件id查询文件信息
export async function getAttachById(params) {
  return getRequest('/api/attach/getAttachById', params);
}


// 个人、单位、专家 通过查询条件下载会员信息Excel
export const xhrGetRequest = (url, user) => {
  var xhr = new XMLHttpRequest(); // ⽤这种原⽣请求下载后端返回的⼆进制流打开就不会出现空⽩
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function () {
    let url = window.URL.createObjectURL(this.response);
    let a = document.createElement('a');
    let filename = user + '查询信息.xls';
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    a.click()
  };
  xhr.send()
};

export { simpleUploadFile, multipleUploadFile }

//短信、邮件发送
// 导入人员文档
export async function getEmailRecipientJson(params) {
  return postRequest(`/api/fileUpload/getEmailRecipientJson`, params)
}

//根据账号查询推荐函附件
export async function getAttachByUserAccount(params) {
  return getRequest(`/api/user/getAttachByUserAccount`, params)
}
//通过手机号查询审核退回个人会员
export async function getUserById(params) {
  return getRequest(`/api/user/selectUserByMobileTel`, params)
}
